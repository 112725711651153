interface HeaderPanelProps {
  title: string;
  onClose: () => void;
  children: React.ReactNode;
}

export const HeaderPanel: React.FC<HeaderPanelProps> = ({
  title,
  onClose,
  children,
}: HeaderPanelProps) => (
  <div
    className="p-4 panel-width panel-height"
    style={{
      flexWrap: 'initial',
      maxHeight: '100%',
      overflowY: 'auto',
      flexGrow: 1,
    }}
  >
    <div className="d-flex justify-content-between align-items-center mb-3">
      <h3>{title}</h3>
      <button className="btn btn-lg btn-icon btn-flat" onClick={onClose}>
        <i className="icon icon-md ml-auto pointer text-secondary">close</i>
      </button>
    </div>
    {children}
  </div>
);
