import { FC, ReactNode } from 'react';
import { ComponentDetailsDto } from '../api/graph.api.dto';
import { Loading } from '../../../components/common/Loading';
import { ArgoCdIcon, AzureIcon, GithubActionIcon, GithubIcon, KibanaIcon, SonarQubeIcon } from '../Icons';

type ComponentDetailsProps = {
  details: ComponentDetailsDto | undefined;
  isLoading: boolean;
};

const Pill: FC<{ children?: ReactNode }> = ({ children }) => {
  return (
    <span
      className="rounded-circle bg-light bg-opacity-40 d-flex align-items-center justify-content-center m-2"
      style={{ width: '3rem', height: '3rem' }}
    >
      {children}
    </span>
  );
};

const ButtonPill: FC<{ children?: ReactNode }> = ({ children }) => {
  return (
    <span
      className="text-primary fw-standard badge rounded-pill bg-light bg-opacity-40 d-flex align-items-center justify-content-center m-2 px-3"
      style={{ height: 48 }}
    >
      {children}
    </span>
  );
};
const ComponentDetails: FC<ComponentDetailsProps> = ({ details, isLoading }: ComponentDetailsProps) => {
  if (!details && !isLoading) {
    return <div>Please select a node</div>;
  }
  if (isLoading) {
    return <Loading />;
  }
  return (
    <>
      <div className="row">
        <div className="col-6">
          <div className="form-group w-100 mb-0 mt-2">
            <label className="form-label mb-1" htmlFor="department">
              <span className="text-nowrap">Manager department</span>
            </label>
            <div className="w-100">
              <span role="button" id="department">
                {details?.details.department}
              </span>
            </div>
          </div>
        </div>
        <div className="col-6">
          <div className="form-group w-100 mb-0 mt-2">
            <label className="form-label mb-1" htmlFor="manager">
              <span className="text-nowrap">Manager</span>
            </label>
            <div className="w-100">
              <span role="button" id="manager">
              {details?.details.manager}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-6">
          <div className="form-group w-100 mb-0 mt-4">
            <label className="form-label mb-1" htmlFor="application">
              <span className="text-nowrap">Application</span>
            </label>
            <div className="w-100">
              <span role="button" id="application">
              <a href={'https://developer.fr.world.socgen/explore/application/'+details?.details?.application?.kearId} target='_blank'>{details?.details?.application?.iAppliName}</a>
              </span>
            </div>
          </div>
        </div>
        <div className="col-6">
          <div className="form-group w-100 mb-0 mt-4">
            <label className="form-label mb-1" htmlFor="sginitiative">
              <span className="text-nowrap">SG Initiative</span>
            </label>
            <div className="w-100">
              <a href="/" id="sginitiative">
                Open project
              </a>
            </div>
          </div>
        </div>
      </div>

      
      <div className="row">
        <div className="col-6">
          <div className="form-group w-100 mb-0 mt-4">
            <label className="form-label mb-1" htmlFor="application">
              <span className="text-nowrap">Exposition</span>
            </label>
            <div className="w-100">
              {details?.details?.exposition}
            </div>
          </div>
        </div>
        <div className="col-6">
          <div className="form-group w-100 mb-0 mt-4">
            <label className="form-label mb-1" htmlFor="sginitiative">
              <span className="text-nowrap">Lifecycle</span>
            </label>
            <div className="w-100">
              {details?.details?.lifeCycle}
            </div>
          </div>
        </div>
      </div>
      
      <div className="row">
        <div className="col-6">
          <div className="form-group w-100 mb-0 mt-4">
            <label className="form-label mb-1" htmlFor="application">
              <span className="text-nowrap">RTO</span>
            </label>
            <div className="w-100">
              {details?.details?.formattedRto}
            </div>
          </div>
        </div>
        <div className="col-6">
          <div className="form-group w-100 mb-0 mt-4">
            <label className="form-label mb-1" htmlFor="sginitiative">
              <span className="text-nowrap">RPO</span>
            </label>
            <div className="w-100">
              {details?.details?.formattedRpo}
            </div>
          </div>
        </div>
      </div>


      <div className="mt-4 d-flex flex-wrap">
        <Pill>
          <KibanaIcon width={25} height={25} />
        </Pill>
        <Pill>
          <ArgoCdIcon width={25} height={25} />
        </Pill>
        <Pill>
          <GithubIcon width={25} height={25} />
        </Pill>
        <Pill>
          <GithubActionIcon width={25} height={25} />
        </Pill>
        <Pill>
          <AzureIcon width={25} height={25} />
        </Pill>
        <Pill>
          <SonarQubeIcon width={25} height={25} />
        </Pill>
        <ButtonPill>Platformization</ButtonPill>
        <ButtonPill>
          <i className="icon icon-sm me-1">web_asset</i>
          <a href={'https://developer.sgmarkets.com/explore/api/'+details?.details.name+'/v1/information'} target='_blank'>Web app</a>
        </ButtonPill>
      </div>
      <h6 className='mt-3'>Health:</h6>
      <div className="mt-4 d-flex flex-wrap">
        { details?.details.healthCheckUri != '' ? <a target='_blank' href={details?.details.healthCheckUri}><img src="/chart/health.svg" width={32} height={32}/>{details?.details.healthCheckUri}</a> : <></>}
      </div>
      <h6 className='mt-3'>Infrastructure:</h6>
      <div className="mt-4 d-flex flex-wrap">
        {details?.details?.instances.map(instance => {
          return instance.status == "Healthy" ? <div><span><i className="icon icon-md text-success">check_circle</i></span>{instance.name}</div> :
          instance.status == "Unhealthy" ? <div><span><i className="icon icon-md text-danger">check_circle</i></span>{instance.name}</div> : 
          <div><span><i className="icon icon-md text-warning">check_circle</i></span>{instance.name}</div>
        })}
      </div>
      <h6 className='mt-3'>Additionals:</h6>
      <div className="mt-4 d-flex flex-wrap">
        {details?.details?.additionnals.map(instance => {
          return instance.name != 'responseHealth' ? <div className='w-100'>{instance.name}: {instance.value}</div> :
          <div className='w-100'>Requests: 
          {
            JSON.parse(instance.value).map(item => {
              return <div className='w-100 ms-4'>Http code {item.httpResponseCode}: {item.count} requets</div>
            })
          }
          </div>
        })}
      </div>
    </>
  );
};

export default ComponentDetails;
