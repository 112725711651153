import React, { ReactNode, useEffect, useState } from 'react';
import { LeftMenu, Menu } from './LeftMenu';
import { HeaderPanel } from './HeaderPanel';
import { fetchComponentDetails } from '../api/tree.api';
import { ComponentDetailsDto } from '../api/graph.api.dto';
import ComponentDetails from './ComponentDetails';

interface PanelProps {
  selectedNodeId: string | undefined;
  onDisplay: (display: boolean) => void;
  children?: ReactNode;
}

const PanelStyle: React.FC<{ children?: ReactNode }> = ({ children }) => (
  <div className="col-5 col-lg-5 col-xl-3 border-end pe-0">{children}</div>
);

const Panel: React.FC<PanelProps> = ({ selectedNodeId, onDisplay }: PanelProps) => {
  const [selectedMenu, setSelectedMenu] = useState<Menu>('none');
  const [selectedComponent, setSelectedComponent] = useState<ComponentDetailsDto | undefined>(undefined);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    onDisplay(selectedMenu !== 'none');
  }, [onDisplay, selectedMenu]);

  useEffect(() => {
    (async () => {
      if (selectedNodeId) {
        setSelectedComponent(undefined);
        setIsLoading(true);
        const component = await fetchComponentDetails(selectedNodeId);
        setSelectedComponent(component);
        setIsLoading(false);
      }
    })();
  }, [selectedNodeId]);

  const handleSelectMenu = (menu: Menu): void => {
    setSelectedMenu(menu);
  };

  const handleClose = (): void => {
    setSelectedMenu('none');
  };

  return (
    <>
      {selectedMenu === 'none' && <LeftMenu onMenuSelect={handleSelectMenu} selectedMenu={selectedMenu} />}
      <>
        {selectedMenu === 'details' && (
          <PanelStyle>
            <div className="d-flex">
              <LeftMenu onMenuSelect={handleSelectMenu} selectedMenu={selectedMenu} />
              <HeaderPanel title={selectedComponent?.name ?? ''} onClose={handleClose}>
                <ComponentDetails details={selectedComponent} isLoading={isLoading} />
              </HeaderPanel>
            </div>
          </PanelStyle>
        )}
      </>
    </>
  );
};

export default Panel;
