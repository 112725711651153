import { FC, ReactNode, useEffect, useMemo } from 'react';
import { useAtom } from 'jotai';
import { isEqual } from 'lodash-es';

import { PanZoom, PanZoomCanvas } from './PanZoom';
import { TREE_ATOM } from '../../atoms/tree.atoms';
import { TreeChart } from './TreeChart';
import { computeTreeLayout } from '../../service/layoutTree.service';
import { Tree as TreeType, TreeNode } from '../../tree.typing';

export type TreeProps = {
  // custom class on the container area where the tree can be manipulated
  containerClass?: string;
  // selected node id where the center viewport can be applied
  // by default, if you click on a tree node, the viewport applies center on it
  // with this prop, we can override the selected node
  // (for instance it can be useful if we want to plug a search bar to search a node and focus on it)
  selectedNodeId?: string;
  // the tree data to draw the tree view (the tree structure is defined in tree.typing.ts file)
  tree: TreeType;
  // function to customize the rendering of the tree node
  // by default, the node badge class is applied, but we can customize the rendering inside the circle
  renderNode: (node: TreeNode) => ReactNode;
  // react on the tree node click (to trigger an action, or to fetch the rest of the tree structure on server-side for instance)
  onNodeClick?: (node: TreeNode) => void | Promise<void>;
  // react on a button click, attached to a tree node
  // (the buttons can be configured by setting the buttons attribute on a tree node, see tree.typing.ts file)
  onButttonClick?: (buttonId: string, node: TreeNode) => void;
};

const Tree: FC<TreeProps> = ({
  containerClass,
  tree,
  selectedNodeId,
  renderNode,
  onNodeClick,
  onButttonClick,
}: TreeProps) => {
  const [treeAtom, setTreeAtom] = useAtom(TREE_ATOM);

  useEffect(() => {
    if (!isEqual(tree, treeAtom)) {
      setTreeAtom(tree);
    }
  }, [tree, setTreeAtom, treeAtom]);

  const chartLayout = useMemo(() => computeTreeLayout(tree), [tree]);

  return (
    <div className={containerClass ?? ''}>
      <PanZoom>
        <PanZoomCanvas>
          <TreeChart {...{ selectedNodeId, renderNode, onNodeClick, onButttonClick }} layoutTree={chartLayout} />
        </PanZoomCanvas>
      </PanZoom>
    </div>
  );
};

export default Tree;
