import classNames from 'classnames';
import React, { FC } from 'react';
import { flushSync } from 'react-dom';
import { Point } from '../../tree.typing';
import { PanZoomContext } from '../../hooks/usePanZoom';

import './PanZoom.scss';

export type ITransform = Point & { scale: number };

export type MoveTo = (x: number, y: number, smooth?: boolean) => void;
export type MoveBy = (dx: number, dy: number, smooth?: boolean) => void;
export type ZoomTo = (x: number, y: number, newScale: number, smooth?: boolean) => void;
export type ZoomBy = (dScale: number, dx?: number, dy?: number) => void;
export type SetScale = (scale: number) => void;
export type GetTransform = () => ITransform;
export type GetContainer = () => HTMLDivElement | null;

export interface IPanZoom {
  moveTo: MoveTo;
  moveBy: MoveBy;
  zoomTo: ZoomTo;
  zoomBy: ZoomBy;
  setScale: SetScale;
  getTransform: GetTransform;
  getContainer: GetContainer;
}

export interface IPanZoomProps {
  panOnlyOnBackground?: boolean;
  disabled?: boolean;
  children: React.ReactNode;
}

interface IPanZoomState {
  x: number;
  y: number;
  scale: number;
  pinchDist: number;
  transitioning: boolean;
}

export class PanZoom extends React.PureComponent<IPanZoomProps, IPanZoomState> {
  private mouseX = 0;
  private mouseY = 0;
  private scaleFactor = 0.15;
  private minZoom = 0.2;
  private maxZoom = 1.5;
  private lastWidth = window.innerWidth;
  private lastHeight = window.innerHeight;
  private containerRef = React.createRef<HTMLDivElement>();
  private previousSelectStart = document.onselectstart;
  private previousDragStart = document.ondragstart;

  public state: IPanZoomState = {
    x: 0,
    y: 0,
    scale: 1,
    pinchDist: 0,
    transitioning: false,
  };

  private panZoom: IPanZoom;

  constructor(props: IPanZoomProps) {
    super(props);
    this.panZoom = {
      moveTo: this.moveTo,
      moveBy: this.moveBy,
      zoomTo: this.zoomTo,
      zoomBy: this.zoomBy,
      setScale: this.setScale,
      getTransform: this.getTransform,
      getContainer: this.getContainer,
    };
  }

  public componentDidMount() {
    if (!this.props.disabled) {
      const container = this.containerRef.current as HTMLElement;

      container.addEventListener('mousedown', this.handleMouseDown);
      container.addEventListener('touchstart', this.handleTouchStart);
      container.addEventListener('wheel', this.handleMouseWheel);

      // needs to be document.body to prevent unwanted mobile effects
      document.body.addEventListener('touchmove', this.handleBodyTouchMove);
      window.addEventListener('resize', this.handleResize);
    }
  }

  public componentWillUnmount() {
    const container = this.containerRef.current as HTMLElement;

    container.removeEventListener('mousedown', this.handleMouseDown);
    container.removeEventListener('touchstart', this.handleTouchStart);
    container.removeEventListener('wheel', this.handleMouseWheel);

    document.body.removeEventListener('touchmove', this.handleBodyTouchMove);
    window.removeEventListener('resize', this.handleResize);
  }

  public render() {
    const { children, disabled } = this.props;
    const { transitioning } = this.state;

    const contextValue = { disabled, transitioning, containerRef: this.containerRef, panZoom: this.panZoom };
    return <PanZoomContext.Provider value={contextValue}>{children}</PanZoomContext.Provider>;
  }

  public moveTo = (x: number, y: number, smooth = true) => {
    this.setState({ x, y, transitioning: smooth ? true : false });

    if (smooth) {
      setTimeout(() => this.setState({ transitioning: false }), 300);
    }
  };

  public moveBy = (dx: number, dy: number, smooth = true) => {
    this.setState(({ x, y }) => ({
      x: x + dx,
      y: y + dy,
      transitioning: smooth,
    }));

    if (smooth) {
      setTimeout(() => this.setState({ transitioning: false }), 300);
    }
  };

  public zoomTo = (x: number, y: number, newScale: number, smooth = false) => {
    const clampedScale = Math.max(this.minZoom, Math.min(this.maxZoom, newScale));
    if (clampedScale > this.minZoom && clampedScale < this.maxZoom) {
      const ratio = 1 - clampedScale / this.state.scale;

      this.setState((prevState) => ({
        x: prevState.x + (x - prevState.x) * ratio,
        y: prevState.y + (y - prevState.y) * ratio,
        scale: newScale,
        transitioning: smooth,
      }));

      if (smooth) {
        setTimeout(() => this.setState({ transitioning: false }), 150);
      }
    }
  };

  private zoomBy = (dScale: number, dx: number = 0, dy: number = 0) => {
    const { scale } = this.state;
    const { innerWidth, innerHeight } = window;

    const sign = Math.sign(dScale);
    const modifier = 1 + Math.abs(dScale);

    const newScale = scale * (sign > 0 ? modifier : 1 / modifier);

    this.zoomTo((innerWidth + dx) / 2, (innerHeight + dy) / 2, newScale);
  };

  private setScale = (newScale: number) => {
    this.setState({ scale: newScale });
  };

  public getTransform = () => {
    const { x, y, scale } = this.state;
    return { x, y, scale };
  };

  public getContainer = () => {
    return this.containerRef.current;
  };

  private handleResize = (e: UIEvent) => {
    const { innerWidth, innerHeight } = window;
    const dx = this.lastWidth - innerWidth;
    const dy = this.lastHeight - innerHeight;
    this.moveBy(-dx / 2, -dy / 2, false);

    this.lastWidth = innerWidth;
    this.lastHeight = innerHeight;
  };

  private handleMouseDown = (e: MouseEvent) => {
    const { clientX, clientY, button, target } = e;
    const { panOnlyOnBackground } = this.props;

    if (panOnlyOnBackground && target !== this.containerRef.current) {
      return;
    }

    if (button === 0) {
      this.previousSelectStart = document.onselectstart;
      this.previousDragStart = document.ondragstart;
      document.onselectstart = this.handleDisabled;
      document.ondragstart = this.handleDisabled;

      document.addEventListener('mousemove', this.handleMouseMove);
      document.addEventListener('mouseup', this.handleMouseUp);

      this.mouseX = clientX;
      this.mouseY = clientY;
    }
  };

  private handleDisabled = (e: Event) => {
    e.stopPropagation();
    return false;
  };

  private handleMouseMove = (e: MouseEvent) => {
    const { clientX, clientY } = e;

    flushSync(() => {
      this.setState((prevState) => ({
        x: prevState.x + clientX - this.mouseX,
        y: prevState.y + clientY - this.mouseY,
      }));
    });

    this.mouseX = clientX;
    this.mouseY = clientY;
  };

  private handleMouseUp = () => {
    document.onselectstart = this.previousSelectStart;
    document.ondragstart = this.previousDragStart;

    document.removeEventListener('mousemove', this.handleMouseMove);
    document.removeEventListener('mouseup', this.handleMouseUp);
  };

  private handleTouchStart = (e: TouchEvent) => {
    const { target } = e;
    const { panOnlyOnBackground } = this.props;

    if (panOnlyOnBackground && target !== this.containerRef.current) {
      return;
    }

    if (e.touches.length === 1) {
      const { clientX, clientY } = e.touches[0];
      this.mouseX = clientX;
      this.mouseY = clientY;
    } else if (e.touches.length === 2) {
      const [touch1, touch2] = Array.from(e.touches);
      this.mouseX = (touch1.clientX + touch2.clientX) / 2;
      this.mouseY = (touch1.clientY + touch2.clientY) / 2;
    }

    document.addEventListener('touchmove', this.handleTouchMove);
    document.addEventListener('touchend', this.handleTouchEnd);
    document.addEventListener('touchcancel', this.handleTouchEnd);
  };

  private handleTouchMove = (e: TouchEvent) => {
    if (e.touches.length === 1) {
      const { clientX, clientY } = e.touches[0];

      this.setState(({ x, y }) => ({
        x: x + clientX - this.mouseX,
        y: y + clientY - this.mouseY,
      }));

      this.mouseX = clientX;
      this.mouseY = clientY;
    } else if (e.touches.length === 2) {
      const [touch1, touch2] = Array.from(e.touches);
      const { pinchDist } = this.state;

      const newPinchDist = Math.hypot(touch1.clientX - touch2.clientX, touch1.clientY - touch2.clientY);
      const clientX = (touch1.clientX + touch2.clientX) / 2;
      const clientY = (touch1.clientY + touch2.clientY) / 2;

      this.setState(({ x, y }) => ({
        x: x + clientX - this.mouseX,
        y: y + clientY - this.mouseY,
        pinchDist: newPinchDist,
      }));

      this.mouseX = clientX;
      this.mouseY = clientY;

      if (Math.abs(newPinchDist - pinchDist) > 5) {
        const newScale = Math.sign(newPinchDist - pinchDist) * this.scaleFactor;
        this.zoomBy(newScale);
      }
    }
  };

  private handleTouchEnd = (e: TouchEvent) => {
    document.removeEventListener('touchmove', this.handleTouchMove);
    document.removeEventListener('touchend', this.handleTouchEnd);
    document.removeEventListener('touchcancel', this.handleTouchEnd);
  };

  private handleBodyTouchMove = (e: TouchEvent) => {
    // prevent rubber band effects and default pinch zooming on mobile browsers
    e.preventDefault();
  };

  private handleMouseWheel = (e: WheelEvent) => {
    const { clientX, clientY, deltaY } = e;
    const normalizedZoom = -Math.sign(deltaY) * this.scaleFactor; // scale factor;
    const newScale = this.state.scale + this.state.scale * normalizedZoom;

    this.zoomTo(clientX, clientY, newScale);
  };
}

export const PanZoomCanvas: FC<{ children: React.ReactNode }> = ({ children }) => {
  const { containerRef, disabled, transitioning, panZoom } = React.useContext(PanZoomContext);
  const { x, y, scale } = panZoom.getTransform();
  const transform = `matrix(${scale}, 0, 0, ${scale}, ${x}, ${y}`;

  return (
    <div className={classNames('pan-zoom', { disabled })} ref={containerRef}>
      <div className={classNames('inner-container', { transitioning })} style={{ transform, transformOrigin: '0 0' }}>
        {children}
      </div>
    </div>
  );
};
