import { FC } from 'react';
import { AutoTextSize } from './AutoTextSize';

type HeadingProps = {
  heading: string;
};

export const NodeHeading: FC<HeadingProps> = ({ heading }: HeadingProps) => (
  <AutoTextSize
    maxSize={20}
    minSize={10}
    className="node-heading font-weight-medium text-center text-truncate"
    style={{ maxWidth: 200 }}
  >
    {heading}
  </AutoTextSize>
);

export const NodeSubheading: FC<HeadingProps> = ({ heading }: HeadingProps) => (
  <div
    className="node-subheading text-secondary font-weight-medium text-center text-truncate"
    style={{ maxWidth: 200 }}
  >
    <span>{heading}</span>
  </div>
);
