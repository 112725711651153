import classNames from "classnames";
import React from "react";

export interface IAutoTextSizeProps extends React.HTMLAttributes<HTMLDivElement> {
  maxSize: number;
  minSize: number;
  truncate?: boolean;
}

interface IAutoTextSizeState {
  fontSize: number | null;
}

export class AutoTextSize extends React.PureComponent<IAutoTextSizeProps, IAutoTextSizeState> {
  public state = {
    fontSize: this.props.maxSize,
  };

  private containerRef = React.createRef<HTMLDivElement>();
  private spanRef = React.createRef<HTMLSpanElement>();

  public componentDidMount() {
    this.setFontSizeState();
  }

  public render() {
    const { children, maxSize, minSize, truncate, className, style, ...rest } = this.props;
    const { fontSize } = this.state;

    const truncation = { "text-truncate": typeof truncate === "undefined" ? true : truncate };
    const finalClasses = classNames("text-nowrap", truncation, className);
    const finalStyles = { fontSize, ...style };

    return (
      <div ref={this.containerRef} className={finalClasses} style={finalStyles} {...rest}>
        <span ref={this.spanRef}>{children}</span>
      </div>
    );
  }

  private setFontSizeState() {
    const { maxSize, minSize } = this.props;

    this.setState({
      fontSize: this.getFontSize(this.containerRef.current, this.spanRef.current, maxSize, minSize),
    });
  }

  private getFontSize(
    container: HTMLDivElement | null,
    span: HTMLSpanElement | null,
    maxSize: number,
    minSize: number
  ) {
    if (!container || !span) {
      return maxSize;
    }

    const containerWidth = container.clientWidth;
    const spanWidth = span.offsetWidth;

    if (spanWidth < containerWidth) {
      return maxSize;
    }

    const multiplier = containerWidth / spanWidth;
    const size = Math.max(Math.floor(maxSize * multiplier), minSize);
    return !Number.isNaN(size) ? size : null;
  }
}
