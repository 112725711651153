import { FC, useState, ReactNode, useEffect } from 'react';
import { fetchEntourage } from './api/tree.api';
import { NodeIconSwitcher } from './IconSwitcher';
import { Tree, TreeComponent, TreeNode } from '../../components/tree';
import Panel from './panel/Panel';
import { NodeTypeDto } from './api/graph.api.dto';
import { mergeTrees } from '../../components/tree';

import './StatusTree.scss';

const StatusTree: FC = () => {
  const [treeConsumer, setTreeConsumer] = useState<Tree | undefined>(undefined);
  const [displayPanel, setDisplayPanel] = useState<boolean>(false);
  const [selectedNodeId, setSelectedNodeId] = useState<string | undefined>(undefined);

  useEffect(() => {
    (async () => {
      const tree = await fetchEntourage();
      setTreeConsumer(tree);
      setSelectedNodeId(tree?.rootId);
    })();
  }, []);

  const handleOnNodeClick = async ({ id }: TreeNode) => {
    if (!treeConsumer) {
      return;
    }
    treeConsumer.nodes[id].isLoading = true;
    setTreeConsumer({ ...treeConsumer });
    const treeResult = await fetchEntourage(id);
    const newTree = mergeTrees(treeConsumer, treeResult);
    newTree.nodes[id].isLoading = false;
    setTreeConsumer(newTree);
    setSelectedNodeId(id);
  };

  const renderNode = (node: TreeNode): ReactNode => {
    const status = node.customMetadata?.['status'];
    const type = node.customMetadata?.['type'] as NodeTypeDto;
    const backgroundClass = `bg-${status === 'ok' ? 'success' : status === 'ko' ? 'danger' : 'warning'}`;
    const nodeCircleClassName =
      'node-badge-link flex-center w-100 h-100 overflow-hidden rounded-circle border border-primary';
    const selectedNodeClassName = selectedNodeId === node.id ? 'border-3' : '';
    const nodeClassName = `${nodeCircleClassName} ${backgroundClass} ${selectedNodeClassName} bg-opacity-40`;

    return (
      <div className={nodeClassName}>
        <NodeIconSwitcher type={type} />
      </div>
    );
  };

  return (
    <div className="row g-0 panel-height flex-nowrap ms-0 px-0">
      <Panel onDisplay={setDisplayPanel} selectedNodeId={selectedNodeId} />
      <div className={`bg-lvl2 d-flex flex-column px-4 panel-width ${displayPanel ? 'col-7 col-lg-7 col-xl-9' : 'panel-width'}`}>
        {treeConsumer ? (
          <TreeComponent
            containerClass="bg-lvl2 status-tree-container"
            tree={treeConsumer}
            onButttonClick={console.log}
            onNodeClick={handleOnNodeClick}
            renderNode={renderNode}
            selectedNodeId={selectedNodeId}
          />
        ) : null}
      </div>
    </div>
  );
};

export default StatusTree;
