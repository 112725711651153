import { FC } from 'react';
import { ButtonItem, ButtonPositionSide } from '../../tree.typing';
import { NodeButton } from './NodeButton';

type ButtonTopLeftPosition = { top: number; left: number };
type NodeButtonsProps = {
  side: ButtonPositionSide;
  items: ButtonItem[];
  onButtonClick: (buttonId: string) => void;
};

function* computePosition(
  num: number,
  parentSize: number,
  elementSize: number,
  side: ButtonPositionSide,
  horizontalOffset?: number
): Iterator<ButtonTopLeftPosition> {
  const multiplier = side === 'right' ? 1 : -1;

  const parentRadius = parentSize / 2;
  const elementRadius = elementSize / 2;

  const actualOffset = horizontalOffset || 0;
  const cx = side === 'right' ? actualOffset : elementSize + actualOffset;
  const cy = -elementRadius;

  const radius = parentRadius + elementRadius;
  const step = Math.PI / 7;
  let angle = step / 2 - (step * num) / 2;

  for (let i = 0; i < num; i++) {
    yield {
      top: cy + Math.round(radius * Math.sin(angle)),
      left: multiplier * (cx + Math.round(radius * Math.cos(angle))),
    };

    angle += step;
  }

  return;
}

const NodeButtons: FC<NodeButtonsProps> = ({ side, items, onButtonClick }: NodeButtonsProps) => {
  const parentSize = 120;
  const elementSize = 30;
  const horizontalOffset = -5;
  const childCount = items.length;
  const position = computePosition(childCount, parentSize, elementSize, side, horizontalOffset);

  return (
    <ul className="node-badge-button-container node-buttons">
      {items.map(({ customRender, ...itemProps }) => (
        <li key={itemProps.id} className="node-badge-button" style={position.next().value}>
          <NodeButton title={itemProps.title} onClick={() => onButtonClick(itemProps.id)}>
            {customRender ? (
              customRender(itemProps)
            ) : (
              <i className={`icon icon-md ${itemProps.className ?? ''}`}>{itemProps.icon}</i>
            )}
          </NodeButton>
        </li>
      ))}
    </ul>
  );
};

export default NodeButtons;
