import { SgmOrderPizzaGraphNodeDto } from './graph.api.dto';
import { GraphDetailsDto } from './graphdetails.api.dto';

const API_URL =
  'https://sgm-status-dev.sgr-3819-dev-insight-kube12-dev.wkube.eu-fr-paris.cloud.socgen/api/v1/graphs/sgm-order-pizza';

  const API_DETAILS_URL =
  'https://sgm-status-dev.sgr-3819-dev-insight-kube12-dev.wkube.eu-fr-paris.cloud.socgen/api/v1/graph_item_details?serviceKey=sgm-order-pizza&code=';


  export async function fetchGraph(): Promise<SgmOrderPizzaGraphNodeDto> {
    const response = await fetch(API_URL);
    return await response.json();
  }

  export async function fetchGraphDetails(apiCode?: string ): Promise<GraphDetailsDto> {
    const response = await fetch(API_DETAILS_URL+apiCode);
    return await response.json();
  }
    