import { TreeNode } from './tree.typing';

export const SIMULATION_ROOT: TreeNode = {
  id: '@simulation-root',
  parent: undefined,
  title: '',
  subtitle: '',
  buttons: [],
  children: [],
  customMetadata: {},
  isLoading: false,
};

export const NODE_WIDTH: number = 270;
export const NODE_HEIGHT: number = 290;
