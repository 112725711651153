import { IPanZoom } from '../components/chart/PanZoom';
import { Layout, LayoutTree, TreeNode } from '../tree.typing';
import { NODE_HEIGHT } from '../tree.const';

export interface Viewport {
  type: 'center';
  query: string;
  smooth?: boolean;
}

export class ViewportService {
  constructor(private panZoom: IPanZoom, private chartLayout: LayoutTree) {}

  public updateViewport = (viewport: Viewport) => {
    const { type, query } = viewport;
    const smooth = viewport.smooth ?? true;

    const deltaX = 0;
    const queryNode = this.chartLayout.nodes[query || ''];

    if (!queryNode) {
      return;
    }

    if (type === 'center') {
      this.centerOn(queryNode, deltaX, 0, smooth);
    }
  };

  // center smoothly by preserving zoom level
  public centerOn(node: Layout<TreeNode>, dx = 0, dy = 0, smooth = true) {
    if (!node) {
      return;
    }

    const { moveTo, getTransform, getContainer } = this.panZoom;
    const { clientWidth, clientHeight } = getContainer() as HTMLDivElement;
    const { x, y } = node;
    const { scale } = getTransform();
    const targetX = clientWidth / 2 - x * scale + dx;
    const targetY = clientHeight / 2 - (y + NODE_HEIGHT / 2) * scale + dy;
    moveTo(targetX, targetY, smooth);
  }
}
