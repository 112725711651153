import { FC } from 'react';
import { Size } from '../../components/tree';

export const ApiIcon: FC = () => (
  <div>
    <i className="icon icon-lg" style={{ cursor: 'pointer' }}>
      device_hub
    </i>
    <br />
    <div className="mt-2">api</div>
  </div>
);

export const AzureFrontDoorIcon: FC<SvgIconProps> = ({ width = 85, height = 85 }: SvgIconProps) => (
  <div style={{ marginTop: -20 }}>
    <svg
      width={`${width}px`}
      height={`${height}px`}
      viewBox="0 0 124.693 119.031"
      color-interpolation-filters="sRGB"
      className="st4"
    >
      <defs id="Patterns_And_Gradients">
        <linearGradient id="grad0-6" x1="0" y1="0" x2="1" y2="0" gradientTransform="rotate(-90 0.5 0.5)">
          <stop offset="0" stop-color="#0078d4" stop-opacity="1" />
          <stop offset="0.82" stop-color="#5ea0ef" stop-opacity="1" />
        </linearGradient>
      </defs>
      <g>
        <title>Page-1</title>
        <g id="group3061-1" transform="translate(34,-34)">
          <title>Azure Front Door</title>
          <g id="group3062-2">
            <title>Icon-networking-73</title>
            <g id="shape3063-3" transform="translate(0,-9.94801)">
              <title>Sheet.3063</title>
              <path
                d="M56.69 106.14 A12.5984 12.8685 -180 0 0 45.64 93.82 A16.063 16.4073 -180 0 0 29.1 77.96 A16.4724 16.8256
								 -180 0 0 13.35 88.93 A15.2126 15.5387 -180 0 0 0 103.85 A15.4331 15.7639 -180 0 0 15.97 119.01 L43.21
								 119.01 A2.45669 2.50936 -180 0 0 43.91 119.01 A12.8819 13.158 -180 0 0 56.69 106.14 Z"
                className="st1"
              />
            </g>
            <g id="shape3064-7" transform="translate(20.8819,-10.0053)">
              <title>Sheet.3064</title>
              <rect x="0" y="96.5111" width="15.937" height="22.5199" className="st2" />
            </g>
            <g id="shape3065-9" transform="translate(27.748,0)">
              <title>Sheet.3065</title>
              <path
                d="M11.97 85.06 L0.98 78.62 a0.787402 0.804282 -180 0 0 -0.976378 0.482569 L0 118.23 a0.787402 0.804282
								 -180 0 0 0.755906 0.804282 L1.01 119.03 L12 112.4 a0.787402 0.804282 -180 0 0 0.535433 -0.739939
								 L12.54 85.93 a0.818898 0.836453 -180 0 0 -0.566929 -0.868624 Z"
                className="st3"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
    <div style={{ marginTop: -20 }}>frontdoor</div>
  </div>
);

export const AppGatewayIcon: FC<SvgIconProps> = ({ width = 40, height = 40 }: SvgIconProps) => (
  <div>
    <svg
      width={`${width}px`}
      height={`${height}px`}
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 714.56 714.56"
    >
      <title>azure_application_gateway_logo_freelogovectors.net</title>
      <path
        className="cls-1"
        d="M373.93,732.28A66.51,66.51,0,0,0,421.13,713L713,423.28a66.54,66.54,0,0,0,19.31-47.21c0-19.3-6.43-34.33-19.31-47.2L421.13,37a67.35,67.35,0,0,0-94.41,0L37,326.72a67.35,67.35,0,0,0,0,94.41L326.71,710.82C339.59,723.7,356.77,732.28,373.93,732.28Z"
        transform="translate(-17.72 -17.72)"
      />
      <path
        className="cls-2"
        d="M577.78,496.24h-133L492,449l-90.13-90.12V575.65h66.53l-94.41,94.41-94.41-94.41h66.52V361.06l-90.12,90.12,47.2,47.21H170.07v-133l47.21,47.21L331,298.82c-17.17-6.43-30-19.3-42.91-34.33-36.49-49.35-27.9-118,21.46-154.5,19.31-15,42.91-23.61,66.52-23.61,32.19,0,66.53,15,88,42.92,36.48,49.35,27.9,118-21.45,154.5-6.44,6.44-15,10.72-21.47,12.87L534.87,410.4l47.21-47.21v133h-4.3Z"
        transform="translate(-17.72 -17.72)"
      />
      <path className="cls-2" d="M376.06,159.34" transform="translate(-17.72 -17.72)" />
      <path className="cls-2" d="M376.06,159.34Z" transform="translate(-17.72 -17.72)" />
      <path
        className="cls-2"
        d="M453.32,247.32a2.1,2.1,0,0,1-2.15,2.14A2.1,2.1,0,0,0,453.32,247.32Z"
        transform="translate(-17.72 -17.72)"
      />
      <path className="cls-2" d="M464,225.85Z" transform="translate(-17.72 -17.72)" />
      <path
        className="cls-1"
        d="M464,228q-6.44-3.23-19.31-12.87h-2.14c-8.59,6.44-21.47,4.29-27.9-4.29-4.29-6.44-6.44-15-2.15-21.46-12.87-8.58-23.61-19.31-36.48-30l-25.75,19.31L346,182.94c4.29,8.58,4.29,19.31-2.15,27.9,2.15,2.14,4.3,2.14,6.44,4.29,8.59,6.44,17.17,12.87,25.75,17.16,8.59-6.44,19.32-4.29,25.75,4.29,2.16,2.14,2.16,4.29,4.3,8.58,23.61,6.44,40.77,4.29,47.21,2.15,4.28-6.44,8.58-12.87,10.72-19.32Z"
        transform="translate(-17.72 -17.72)"
      />
      <path
        className="cls-2"
        d="M466.19,223.71c0,2.14,0,2.14-2.15,4.29C464,225.85,464,223.71,466.19,223.71Z"
        transform="translate(-17.72 -17.72)"
      />
      <path className="cls-2" d="M466.19,219.42Z" transform="translate(-17.72 -17.72)" />
      <path
        className="cls-1"
        d="M449,135.73c-2.15,0-25.76-2.14-60.09,17.17,10.73,10.72,21.47,21.46,32.19,30a20.47,20.47,0,0,1,25.75,6.44c4.3,6.43,6.44,12.87,2.16,19.31,8.57,6.43,12.87,10.72,17.16,12.87,6.44-30.05,2.14-60.09-17.16-85.84Z"
        transform="translate(-17.72 -17.72)"
      />
      <path
        className="cls-2"
        d="M434,120.72l15,15A50.15,50.15,0,0,0,434,120.72Z"
        transform="translate(-17.72 -17.72)"
      />
      <path
        className="cls-1"
        d="M343.88,103.55c8.58,10.73,17.17,19.31,25.75,30.05,25.75-12.88,49.35-15,64.37-12.88-17.15-15-38.62-21.45-60.07-21.45-10.73,0-19.32,2.14-30.05,4.29Z"
        transform="translate(-17.72 -17.72)"
      />
      <path className="cls-2" d="M331,107.84c12.87-4.29,12.87-4.29,12.87-6.44Z" transform="translate(-17.72 -17.72)" />
      <path
        className="cls-1"
        d="M305.26,127.16c-2.14,8.58-2.14,23.61,6.44,40.77a18.35,18.35,0,0,1,15,0l4.29-4.29c8.58-8.58,17.17-17.16,25.76-23.6A169.43,169.43,0,0,1,331,107.84c-6.43,2.15-10.73,6.44-15,10.73-4.29,2.15-8.59,6.43-10.73,8.58Z"
        transform="translate(-17.72 -17.72)"
      />
      <path
        className="cls-1"
        d="M294.53,249.47A86.15,86.15,0,0,1,301,217.28l-2.15-2.14c-8.58-10.73-8.58-25.76,0-34.34-6.43-15-6.43-30-4.29-38.62-21.45,30-23.61,73,0,107.29Z"
        transform="translate(-17.72 -17.72)"
      />
      <path
        className="cls-2"
        d="M294.53,249.47C296.68,251.61,296.68,249.47,294.53,249.47Z"
        transform="translate(-17.72 -17.72)"
      />
      <path className="cls-2" d="M313.84,268.78Z" transform="translate(-17.72 -17.72)" />
      <path
        className="cls-1"
        d="M313.84,268.78c17.17,15,38.62,21.46,60.09,21.46a98.79,98.79,0,0,0,57.93-19.31c2.14-2.14,6.44-4.28,8.58-6.43-8.58,0-21.45,0-36.47-4.29-2.16,2.14-2.16,4.29-4.3,4.29-8.57,6.43-19.3,4.29-27.89-4.29-2.15-4.29-4.3-8.59-4.3-12.87a196.56,196.56,0,0,1-27.9-17.17c-2.14-2.15-6.44-4.29-8.58-6.43-4.29,2.14-8.57,2.14-12.87,2.14-4.29,17.17-6.43,32.19-4.29,42.92Z"
        transform="translate(-17.72 -17.72)"
      />
    </svg>
    <div>gateway</div>
  </div>
);

export const ApimIcon: FC<SvgIconProps> = ({ width = 40, height = 40 }: SvgIconProps) => (
  <div>
    <svg xmlns="http://www.w3.org/2000/svg" width={`${width}px`} height={`${height}px`} viewBox="0.5 1.179 17 15.656">
      <defs>
        <linearGradient id="a" x1="9" y1="16.82" x2="9" y2="1.18" gradientUnits="userSpaceOnUse">
          <stop offset="0" stop-color="black" />
          <stop offset=".09" stop-color="black" />
          <stop offset=".24" stop-color="black" />
          <stop offset=".4" stop-color="black" />
          <stop offset=".57" stop-color="black" />
          <stop offset=".78" stop-color="black" />
        </linearGradient>
        <linearGradient id="b" x1="8.36" y1="11.35" x2="8.36" y2="14.46" gradientUnits="userSpaceOnUse">
          <stop offset="0" stop-color="black" />
          <stop offset="1" stop-color="black" />
        </linearGradient>
      </defs>
      <title>Icon-web-42</title>
      <path
        d="M14.18,5.89A4.85,4.85,0,0,0,9.23,1.18,5,5,0,0,0,4.48,4.47,4.61,4.61,0,0,0,.5,9,4.67,4.67,0,0,0,5.29,13.5a3,3,0,0,0,.42,0h1.2a1.47,1.47,0,0,1-.11-.56v0A1.51,1.51,0,0,1,7,12.21H5.6l-.31,0A3.41,3.41,0,0,1,1.77,9,3.33,3.33,0,0,1,4.68,5.73l.76-.12.25-.73A3.73,3.73,0,0,1,9.23,2.45,3.6,3.6,0,0,1,12.91,5.9V7L14,7.15a2.59,2.59,0,0,1,2.26,2.49,2.63,2.63,0,0,1-2.62,2.54h-.15l-.08,0h-1A3.92,3.92,0,0,0,8.54,9a.64.64,0,1,0,0,1.27,2.65,2.65,0,0,1,0,5.29.64.64,0,1,0,0,1.27,3.92,3.92,0,0,0,3.87-3.34h1.05a.64.64,0,0,0,.2,0A3.91,3.91,0,0,0,17.5,9.64,3.86,3.86,0,0,0,14.18,5.89Z"
        fill="url(#a)"
      />
      <rect x="6.8" y="11.35" width="3.12" height="3.12" rx="1.54" fill="url(#b)" />
    </svg>
    <div>apim</div>
  </div>
);

export const DatabaseIcon: FC = () => (
  <div>
    <i className="icon icon-lg" style={{ cursor: 'pointer' }}>
      <span className="icon icon-lg">storage</span>
    </i>
    <br />
    <div className="mt-2">database</div>
  </div>
);

export const ApiInstanceIcon: FC = () => (
  <i className="icon icon-lg" style={{ cursor: 'pointer' }}>
    <span className="icon icon-lg">deployed_code</span>
  </i>
);

export const WebAppIcon: FC = () => (
  <div>
    <i className="icon icon-lg" style={{ cursor: 'pointer' }}>
      web_asset
    </i>
    <br />
    <div className="mt-2">web app</div>
  </div>
);

type SvgIconProps = Partial<Size>;

const DEFAULT_SIZE: Size = { width: 20, height: 20 };

export const ArgoCdIcon: FC<SvgIconProps> = ({
  width = DEFAULT_SIZE.width,
  height = DEFAULT_SIZE.height,
}: SvgIconProps) => (
  <svg
    width={`${width}px`}
    height={`${height}px`}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 128 128"
  >
    <defs>
      <clipPath id="argocd-original-e">
        <path d="M42 41h44v51H42zm0 0" />
      </clipPath>
      <clipPath id="argocd-original-d">
        <path d="M0 0h128v128H0z" />
      </clipPath>
      <clipPath id="argocd-original-c">
        <path d="M0 0h128v128H0z" />
      </clipPath>
      <clipPath id="argocd-original-f">
        <path d="M85.695 41.133l-2.55 58.238H44.887l-2.125-58.238" />
      </clipPath>
      <clipPath id="argocd-original-b">
        <path d="M0 0h128v128H0z" />
      </clipPath>
      <mask id="argocd-original-l">
        <g filter="url(#argocd-original-a)">
          <path d="M0 0h128v128H0z" fillOpacity=".251" />
        </g>
      </mask>
      <mask id="argocd-original-h">
        <g filter="url(#argocd-original-a)">
          <path d="M0 0h128v128H0z" fillOpacity=".22" />
        </g>
      </mask>
      <mask id="argocd-original-j">
        <g filter="url(#argocd-original-a)">
          <path d="M0 0h128v128H0z" fillOpacity=".502" />
        </g>
      </mask>
      <g id="argocd-original-i" clipPath="url(#argocd-original-b)">
        <path d="M58.488 30.508a2.974 2.974 0 11-5.948-.003 2.974 2.974 0 015.948.003zm0 0" fill="#fbdfc3" />
      </g>
      <g id="argocd-original-g" clipPath="url(#argocd-original-c)">
        <path
          d="M84.422 65.363s2.55-22.531-.852-31.031C77.195 19.453 62.316 20.73 62.316 20.73s8.5 3.399 8.926 16.153c.426 8.926 0 22.105 0 22.105zm0 0"
          fill="#e34e3b"
        />
      </g>
      <g id="argocd-original-k" clipPath="url(#argocd-original-d)">
        <path
          d="M83.145 90.867V87.47c-5.95 3.398-12.329 6.8-19.977 6.8-8.504 0-14.031-3.824-19.555-6.8l.422 3.398s6.38 6.805 19.555 6.805c12.328-.426 19.555-6.805 19.555-6.805zm0 0"
          fill="#e9654b"
        />
      </g>
      <filter id="argocd-original-a" filterUnits="objectBoundingBox" x="0%" y="0%" width="100%" height="100%">
        <feColorMatrix in="SourceGraphic" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0" />
      </filter>
    </defs>
    <path
      d="M44.035 89.594s-.847 2.55-2.125 3.824a3.844 3.844 0 01-2.972 1.277 49.946 49.946 0 01-6.38 1.274s2.977.426 6.38.851c1.273 0 1.273 0 2.124.426 2.126 0 2.973-1.277 2.973-1.277zm39.11 0s.851 2.55 2.125 3.824a3.858 3.858 0 002.976 1.277 49.8 49.8 0 006.375 1.274s-2.973.426-6.8.851c-1.274 0-1.274 0-2.126.426-2.55 0-2.976-1.277-2.976-1.277zm0 0"
      fill="#e9654b"
    />
    <path
      d="M109.926 47.508c0 25.355-20.555 45.91-45.91 45.91-25.356 0-45.91-20.555-45.91-45.91 0-25.352 20.554-45.906 45.91-45.906 25.355 0 45.91 20.554 45.91 45.906zm0 0"
      fill="#b6cfea"
    />
    <path
      d="M108.227 47.508c0 24.418-19.793 44.21-44.211 44.21-24.414 0-44.207-19.792-44.207-44.21C19.809 23.094 39.602 3.3 64.016 3.3c24.418 0 44.21 19.793 44.21 44.207zm0 0"
      fill="#e6f5f8"
    />
    <path
      d="M100.148 48.36c0 19.956-16.175 36.132-36.132 36.132-19.954 0-36.133-16.176-36.133-36.133 0-19.953 16.18-36.132 36.133-36.132 19.957 0 36.132 16.18 36.132 36.132zm0 0"
      fill="#d0e8f0"
    />
    <path
      d="M42.762 65.363s2.976 48.035 2.976 48.887c0 .422.426 1.273-1.703 2.125-2.125.848-8.926 2.55-8.926 2.55h10.203c4.676 0 4.676-3.827 4.676-4.675 0-.852 1.274-19.129 1.274-19.129s.425 21.68.425 22.527c0 .852-.425 2.125-3.398 2.977-2.125.426-8.504 1.7-8.504 1.7h9.778c5.953 0 5.953-3.825 5.953-3.825l1.273-19.129s.426 19.129.426 21.254c0 1.7-1.274 2.977-5.953 3.824-2.973.852-6.801 1.703-6.801 1.703h11.055c5.523-.425 6.375-4.254 6.375-4.254l9.351-47.609zm0 0"
      fill="#ee794b"
    />
    <path
      d="M85.27 65.363s-2.973 48.035-2.973 48.887c0 .422-.426 1.273 1.7 2.125 2.124.848 8.925 2.55 8.925 2.55H82.719c-4.676 0-4.676-3.827-4.676-4.675 0-.852-1.273-19.129-1.273-19.129s-.426 21.68-.426 22.527c0 .852.426 2.125 3.402 2.977l8.5 1.7H78.47c-5.95 0-5.95-3.825-5.95-3.825l-1.277-19.129s-.426 19.129-.426 21.254c0 1.7 1.278 2.977 5.954 3.824 2.976.852 6.8 1.703 6.8 1.703H72.52c-5.528-.425-6.38-4.254-6.38-4.254L56.79 74.29zm.425-23.379c0 11.903-9.777 21.254-21.254 21.254-11.476 0-21.254-9.777-21.254-21.254 0-11.476 9.778-21.254 21.254-21.254 11.477 0 21.254 9.352 21.254 21.254zm0 0"
      fill="#ee794b"
    />
    <g clipPath="url(#argocd-original-e)">
      <g clipPath="url(#argocd-original-f)">
        <path
          d="M102.273 53.46c0 20.895-16.937 37.833-37.832 37.833-20.894 0-37.832-16.938-37.832-37.832 0-20.895 16.938-37.832 37.832-37.832 20.895 0 37.832 16.937 37.832 37.832zm0 0"
          fill="#ee794b"
        />
      </g>
    </g>
    <use xlinkHref="#argocd-original-g" mask="url(#argocd-original-h)" />
    <use xlinkHref="#argocd-original-i" mask="url(#argocd-original-j)" />
    <path
      d="M71.668 73.863c0 7.227-3.402 11.907-7.652 11.907s-7.653-5.528-7.653-12.754c0 0 3.403 6.8 8.078 6.8 4.676 0 7.227-5.953 7.227-5.953zm0 0"
      fill="#010101"
    />
    <path
      d="M71.668 73.863c0 4.68-3.402 7.227-7.652 7.227s-7.227-3.399-7.227-7.649c0 0 3.402 4.25 8.078 4.25 4.676 0 6.801-3.828 6.801-3.828zm0 0"
      fill="#fff"
    />
    <path
      d="M92.07 53.887c0 7.277-5.898 13.175-13.175 13.175-7.278 0-13.18-5.898-13.18-13.175 0-7.278 5.902-13.18 13.18-13.18 7.277 0 13.175 5.902 13.175 13.18zm-29.754 0c0 7.277-5.902 13.175-13.18 13.175-7.277 0-13.175-5.898-13.175-13.175 0-7.278 5.898-13.18 13.176-13.18 7.277 0 13.18 5.902 13.18 13.18zm0 0"
      fill="#e9654b"
    />
    <path
      d="M89.098 53.887c0 5.633-4.57 10.203-10.203 10.203-5.633 0-10.204-4.57-10.204-10.203 0-5.637 4.57-10.203 10.204-10.203 5.632 0 10.203 4.566 10.203 10.203zm-30.61 0c0 5.633-4.566 10.203-10.199 10.203-5.637 0-10.203-4.57-10.203-10.203a10.201 10.201 0 0110.203-10.203c5.633 0 10.2 4.566 10.2 10.203zm0 0"
      fill="#fff"
    />
    <path
      d="M51.262 52.61a2.975 2.975 0 11-5.95.003 2.975 2.975 0 015.95-.004zm30.609 0a2.976 2.976 0 11-5.951.001 2.976 2.976 0 015.951-.002zm0 0"
      fill="#010101"
    />
    <path
      d="M17.258 58.988a2.005 2.005 0 01-2.125-2.125V39.86a2.008 2.008 0 01.582-1.543 2.008 2.008 0 011.543-.582 2.005 2.005 0 012.125 2.125v17.004c.035.57-.18 1.133-.586 1.54a2.008 2.008 0 01-1.54.585zm92.668 0a2.003 2.003 0 01-1.54-.586 2.008 2.008 0 01-.585-1.539V39.86a2.011 2.011 0 01.586-1.543 2 2 0 011.539-.582 2 2 0 011.539.582c.41.407.62.97.586 1.543v17.004a1.994 1.994 0 01-.586 1.54 2.003 2.003 0 01-1.54.585zm0 0"
      fill="#b6cfea"
    />
    <path
      d="M51.688 13.504a2.125 2.125 0 11-4.25 0 2.125 2.125 0 014.25 0zM34.262 70.89a1.559 1.559 0 01-1.278-.425c-5.101-6.375-7.652-14.453-7.652-22.531a37.933 37.933 0 015.102-19.13 41.641 41.641 0 0113.601-13.6 1.873 1.873 0 012.13.425 1.874 1.874 0 01-.427 2.125 34.305 34.305 0 00-17.43 29.754 33.487 33.487 0 007.227 20.832c.426.426.426 1.7-.426 2.125-.425.426-.425.426-.847.426zm0 0"
      fill="#fff"
    />
    <use xlinkHref="#argocd-original-k" mask="url(#argocd-original-l)" />
  </svg>
);

export const SonarQubeIcon: FC<SvgIconProps> = ({
  width = DEFAULT_SIZE.width,
  height = DEFAULT_SIZE.height,
}: SvgIconProps) => (
  <svg width={`${width}px`} height={`${height}px`} viewBox=".1 -.1 960.1 911.4" xmlns="http://www.w3.org/2000/svg">
    <path
      d="m832.6 911.3h-52.6c0-424.1-349.9-769.1-779.9-769.1v-52.6c459 0 832.5 368.5 832.5 821.7zm36.4-282.9c-63.2-266-278.7-488-549-565.7l12.1-42.2c285.3 82 512.8 316.5 579.5 597.8zm59.3-249.7c-65.1-142-176.2-265.9-312.5-348.8l18.3-30c142.3 86.6 258 215.8 326.1 364.2z"
      fill="#499cd2"
    />
  </svg>
);

export const GithubIcon: FC<SvgIconProps> = ({
  width = DEFAULT_SIZE.width,
  height = DEFAULT_SIZE.height,
}: SvgIconProps) => (
  <svg
    width={`${width}px`}
    height={`${height}px`}
    viewBox="0 0 1024 1024"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 0C3.58 0 0 3.58 0 8C0 11.54 2.29 14.53 5.47 15.59C5.87 15.66 6.02 15.42 6.02 15.21C6.02 15.02 6.01 14.39 6.01 13.72C4 14.09 3.48 13.23 3.32 12.78C3.23 12.55 2.84 11.84 2.5 11.65C2.22 11.5 1.82 11.13 2.49 11.12C3.12 11.11 3.57 11.7 3.72 11.94C4.44 13.15 5.59 12.81 6.05 12.6C6.12 12.08 6.33 11.73 6.56 11.53C4.78 11.33 2.92 10.64 2.92 7.58C2.92 6.71 3.23 5.99 3.74 5.43C3.66 5.23 3.38 4.41 3.82 3.31C3.82 3.31 4.49 3.1 6.02 4.13C6.66 3.95 7.34 3.86 8.02 3.86C8.7 3.86 9.38 3.95 10.02 4.13C11.55 3.09 12.22 3.31 12.22 3.31C12.66 4.41 12.38 5.23 12.3 5.43C12.81 5.99 13.12 6.7 13.12 7.58C13.12 10.65 11.25 11.33 9.47 11.53C9.76 11.78 10.01 12.26 10.01 13.01C10.01 14.08 10 14.94 10 15.21C10 15.42 10.15 15.67 10.55 15.59C13.71 14.53 16 11.53 16 8C16 3.58 12.42 0 8 0Z"
      transform="scale(64)"
      fill="#1B1F23"
    />
  </svg>
);

export const KibanaIcon: FC<SvgIconProps> = ({
  width = DEFAULT_SIZE.width,
  height = DEFAULT_SIZE.height,
}: SvgIconProps) => (
  <svg
    width={`${width}px`}
    height={`${height}px`}
    viewBox="-43 0 342 342"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    preserveAspectRatio="xMidYMid"
  >
    <g>
      <path
        d="M0,306.929431 L0,340.94153 L15.9856864,340.94153 L156.319606,172.717689 C146.588635,166.392997 136.454031,160.712166 125.980814,155.71164 L0,306.929431 Z"
        fill="#009B90"
      ></path>
      <path
        d="M156.32414,172.717689 L15.9221957,341.009554 L255.571444,341.009554 C255.571444,272.441163 216.661603,211.491482 156.32414,172.717689 Z"
        fill="#40BEB0"
      ></path>
      <polygon fill="#EF5098" points="-5.24025268e-14 0 -1.27897692e-13 307.445368 256 0"></polygon>
      <path
        d="M0.0681886504,307.339209 L126.243164,155.845133 C86.6932342,137.371934 43.6510777,127.804446 0,128.003058 L0.0681886504,307.339209 Z"
        fill="#353535"
      ></path>
    </g>
  </svg>
);

export const GithubActionIcon: FC<SvgIconProps> = ({
  width = DEFAULT_SIZE.width,
  height = DEFAULT_SIZE.height,
}: SvgIconProps) => (
  <svg
    fill="#000000"
    width={`${width}px`}
    height={`${height}px`}
    viewBox="0 0 24 24"
    role="img"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>GitHub Actions</title>
    <path d="M10.984 13.836a.5.5 0 0 1-.353-.146l-.745-.743a.5.5 0 1 1 .706-.708l.392.391 1.181-1.18a.5.5 0 0 1 .708.707l-1.535 1.533a.504.504 0 0 1-.354.146zm9.353-.147l1.534-1.532a.5.5 0 0 0-.707-.707l-1.181 1.18-.392-.391a.5.5 0 1 0-.706.708l.746.743a.497.497 0 0 0 .706-.001zM4.527 7.452l2.557-1.585A1 1 0 0 0 7.09 4.17L4.533 2.56A1 1 0 0 0 3 3.406v3.196a1.001 1.001 0 0 0 1.527.85zm2.03-2.436L4 6.602V3.406l2.557 1.61zM24 12.5c0 1.93-1.57 3.5-3.5 3.5a3.503 3.503 0 0 1-3.46-3h-2.08a3.503 3.503 0 0 1-3.46 3 3.502 3.502 0 0 1-3.46-3h-.558c-.972 0-1.85-.399-2.482-1.042V17c0 1.654 1.346 3 3 3h.04c.244-1.693 1.7-3 3.46-3 1.93 0 3.5 1.57 3.5 3.5S13.43 24 11.5 24a3.502 3.502 0 0 1-3.46-3H8c-2.206 0-4-1.794-4-4V9.899A5.008 5.008 0 0 1 0 5c0-2.757 2.243-5 5-5s5 2.243 5 5a5.005 5.005 0 0 1-4.952 4.998A2.482 2.482 0 0 0 7.482 12h.558c.244-1.693 1.7-3 3.46-3a3.502 3.502 0 0 1 3.46 3h2.08a3.503 3.503 0 0 1 3.46-3c1.93 0 3.5 1.57 3.5 3.5zm-15 8c0 1.378 1.122 2.5 2.5 2.5s2.5-1.122 2.5-2.5-1.122-2.5-2.5-2.5S9 19.122 9 20.5zM5 9c2.206 0 4-1.794 4-4S7.206 1 5 1 1 2.794 1 5s1.794 4 4 4zm9 3.5c0-1.378-1.122-2.5-2.5-2.5S9 11.122 9 12.5s1.122 2.5 2.5 2.5 2.5-1.122 2.5-2.5zm9 0c0-1.378-1.122-2.5-2.5-2.5S18 11.122 18 12.5s1.122 2.5 2.5 2.5 2.5-1.122 2.5-2.5zm-13 8a.5.5 0 1 0 1 0 .5.5 0 0 0-1 0zm2 0a.5.5 0 1 0 1 0 .5.5 0 0 0-1 0zm12 0c0 1.93-1.57 3.5-3.5 3.5a3.503 3.503 0 0 1-3.46-3.002c-.007.001-.013.005-.021.005l-.506.017h-.017a.5.5 0 0 1-.016-.999l.506-.017c.018-.002.035.006.052.007A3.503 3.503 0 0 1 20.5 17c1.93 0 3.5 1.57 3.5 3.5zm-1 0c0-1.378-1.122-2.5-2.5-2.5S18 19.122 18 20.5s1.122 2.5 2.5 2.5 2.5-1.122 2.5-2.5z" />
  </svg>
);

export const AzureIcon: FC<SvgIconProps> = ({
  width = DEFAULT_SIZE.width,
  height = DEFAULT_SIZE.height,
}: SvgIconProps) => (
  <svg
    width={`${width}px`}
    height={`${height}px`}
    viewBox="0 0 512 512"
    xmlns="http://www.w3.org/2000/svg"
    fillRule="evenodd"
    clipRule="evenodd"
    strokeLinejoin="round"
    strokeMiterlimit="2"
  >
    <g fillRule="nonzero">
      <path
        d="M52.091 10.225h40.684L50.541 135.361a6.5 6.5 0 01-6.146 4.412H12.732c-3.553 0-6.477-2.923-6.477-6.476 0-.704.115-1.403.34-2.07L45.944 14.638a6.501 6.501 0 016.147-4.415v.002z"
        fill="url(#prefix___Linear1)"
        transform="translate(2.076 1.626) scale(3.37462)"
      />
      <path
        d="M377.371 319.374H159.644c-5.527 0-10.076 4.549-10.076 10.077 0 2.794 1.164 5.466 3.206 7.37l139.901 130.577a21.986 21.986 0 0015.004 5.91H430.96l-53.589-153.934z"
        fill="#0078d4"
      />
      <path
        d="M52.091 10.225a6.447 6.447 0 00-6.161 4.498L6.644 131.12a6.457 6.457 0 00-.38 2.185c0 3.548 2.92 6.468 6.469 6.468H45.23a6.95 6.95 0 005.328-4.531l7.834-23.089 27.985 26.102a6.622 6.622 0 004.165 1.518h36.395l-15.962-45.615-46.533.011 28.48-83.944H52.091z"
        fill="url(#prefix___Linear2)"
        transform="translate(2.076 1.626) scale(3.37462)"
      />
      <path
        d="M104.055 14.631a6.492 6.492 0 00-6.138-4.406H52.575a6.493 6.493 0 016.138 4.406l39.35 116.594c.225.668.34 1.367.34 2.072 0 3.554-2.924 6.478-6.478 6.478h45.344c3.553-.001 6.476-2.925 6.476-6.478 0-.705-.115-1.404-.34-2.072l-39.35-116.594z"
        fill="url(#prefix___Linear3)"
        transform="translate(2.076 1.626) scale(3.37462)"
      />
    </g>
    <defs>
      <linearGradient
        id="prefix___Linear1"
        x1="0"
        y1="0"
        x2="1"
        y2="0"
        gradientUnits="userSpaceOnUse"
        gradientTransform="rotate(108.701 26.35 33.911) scale(131.7791)"
      >
        <stop offset="0" stopColor="#114a8b" />
        <stop offset="1" stopColor="#0669bc" />
      </linearGradient>
      <linearGradient
        id="prefix___Linear2"
        x1="0"
        y1="0"
        x2="1"
        y2="0"
        gradientUnits="userSpaceOnUse"
        gradientTransform="rotate(161.318 33.644 45.587) scale(10.31703)"
      >
        <stop offset="0" stopOpacity=".3" />
        <stop offset=".07" stopOpacity=".2" />
        <stop offset=".32" stopOpacity=".1" />
        <stop offset=".62" stopOpacity=".05" />
        <stop offset="1" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="prefix___Linear3"
        x1="0"
        y1="0"
        x2="1"
        y2="0"
        gradientUnits="userSpaceOnUse"
        gradientTransform="rotate(69.426 25.69 62.036) scale(131.9816)"
      >
        <stop offset="0" stopColor="#3ccbf4" />
        <stop offset="1" stopColor="#2892df" />
      </linearGradient>
    </defs>
  </svg>
);

// eslint-disable-next-line jsx-a11y/alt-text
export const DoItNowIcon = () => <img src="/din.png" width={25} />;
