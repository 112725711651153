import React, { useEffect, useState } from 'react';
import { getAuthorizationHeader } from '../../helpers/index';
import { configuration } from '../../config/constants';
import Loading from '../../components/common/Loading2';
import { Tooltip } from 'react-tooltip';
import { Link } from 'react-router-dom';
import './style.css';
// @ts-ignore
import OrgChartComponent from './StatusTree';

const StatusChart: React.FC = () => {
  interface ServiceDto {
    name: string;
    key: string;
    type: string;
    status: string;
    description: string;
    environment: string;
    additionnals: Additionnal[];
    checks: Check[];
    instances: Instance[];
    children: ServiceDto[];
    links: Link[];
  }

  interface Instance {
    name: string;
    type: string;
    status: string;
    os: string;
    osPlatform: string;
  }
  interface Link {
    type: string;
    url: string;
    customIconUri: null | string;
  }

  interface Additionnal {
    name: string;
    value: string;
  }

  interface Check {
    name: string;
    key: string;
    type: string;
    status: string;
    description: string;
    additionnals: Additionnal[];
  }
  interface DataGraph {
    id: string;
    parentId?: string;
    name: string;
    status: string;
    type: string;
    zone: string;
    nodes?: string[];
    links: DataGraphLink[];
    certificate: string;
  }

  interface DataGraphLink {
    name: string;
    icon: string;
    link: string;
  }

  interface GraphItem {
    name: string;
    id: number;
  }

  function GetType(type: string) {
    if (type === 'website') {
      return 'cdn.png';
    }
    if (type === 'api') {
      return 'api.png';
    }
    if (type === 'database') {
      return 'db.png';
    }
    if (type === 'frontdoor') {
      return 'frontdoor.svg';
    }
    if (type === 'apim') {
      return 'apim.svg';
    }
    if (type === 'appgateway') {
      return 'appgateway.svg';
    }
    return type;
  }

  function getLinkIcon(type: string) {
    // if (type === 'kibana') {
    //     return <KibanaIcon />;
    // }
    // if (type === 'argocd') {
    //     return <ArgoCdIcon />;
    // }
    // if (type === 'github') {
    //     return <GithubIcon />;
    // }
    // if (type === 'github-actions') {
    //     return <GithubActionIcon />;
    // }
    // if (type === 'azure') {
    //     return <AzureIcon />;
    // }
    if (type === 'do-it-now') {
      return 'din.png';
    }
    return type + '.svg';
  }

  function ParseChild(parentId: string, array: DataGraph[], child: ServiceDto) {
    const item = {} as DataGraph;

    item.id = child.name;
    setId(item.id as any);
    item.parentId = parentId;
    item.name = child.name;
    item.status = child.status;
    item.type = GetType(child.type);
    item.links = [];
    child.links?.map((link) => {
      item.links.push({
        name: link.type,
        icon: getLinkIcon(link.type),
        link: link.url,
      });
    });
    item.nodes = [];
    child.instances?.map((instance) => {
      item.nodes!.push(instance.status);
    });
    array.push(item);

    child.children?.map((_child) => {
      ParseChild(item.id, array, _child);
    });
  }

  const [repeater, setRepeater] = useState(0);

  useEffect(() => {
    setIsLoading(true);
    const requestOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: getAuthorizationHeader(),
      },
    };
    fetch(
      `${configuration.baseApiUrls.status}/api/v1/graphs/sgm-order-pizza`,
      requestOptions as any
    )
      .then((response) => response.json())
      .then((json) => {
        setTree(json);
        setIsLoading(false);

        const array: DataGraph[] = [];
        const graphItems: GraphItem[] = [];
        const item = {} as DataGraph;
        if (json != null) {
          item.id = json.name;
          item.name = json.name;
          item.status = json.status;
          item.type = GetType(json.type);
          item.links = [];
          json.links?.map((link) => {
            item.links.push({
              name: link.type,
              icon: getLinkIcon(link.type),
              link: link.url,
            });
          });
          array.push(item);

          json.children?.map((child) => {
            ParseChild(item.name, array, child);
          });
        }
        console.log(array);
        setData(array);
        setTimeout(() => setRepeater((prevState) => prevState + 1), 1000000);
      });
  }, [repeater]);

  const [tree, setTree] = useState<ServiceDto | null>(null);
  const [data, setData] = useState<DataGraph[] | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [id, setId] = useState<number>(100);

  return (
    <>
      <div className="main-content">
        <div className="container">
          <div className="row pt-5  d-flex">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb mb-4">
                <li className="breadcrumb-item">
                  <Link to="/">Home</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Bookmarked services
                </li>
              </ol>
            </nav>
            <Loading isLoading={isLoading} />
            {!isLoading && (
              <div className="col-12">
                <OrgChartComponent
                  // setClick={(click: any) => (addNodeChildFunc = click)}
                  // onNodeClick={onNodeClick}
                  // @ts-ignore
                  data={data as any}
                />
                <Tooltip id="my-tooltip" />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default StatusChart;
