import { FC } from 'react';
import { Link } from '../../tree.typing';

import './TreeLink.scss';

export type TreeLinkProps = {
  link: Link;
};

export const TreeLink: FC<TreeLinkProps> = ({ link }: TreeLinkProps) => {
  const { x, y, width, parentX, connections } = link;

  const marks = connections.map((connection, index) => {
    return <div className="link-mark" key={`${index}`} style={{ transform: `translate(${connection.x - x}px)` }}></div>;
  });

  return (
    <div className="link-container pointer-events-none" style={{ transform: `translate(${x}px, ${y}px)`, width }}>
      {connections.length > 1 && <div className="link-line" />}
      <div className="link-mark top" style={{ transform: `translate(${-x + parentX}px)` }} />
      {marks}
    </div>
  );
};
