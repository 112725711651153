import { createContext, useContext } from 'react';
import { IPanZoom } from '../components/chart/PanZoom';

interface IPanZoomContextValue {
  panZoom: IPanZoom;
  transitioning: boolean;
  containerRef: React.MutableRefObject<HTMLDivElement | null>;
  disabled?: boolean;
}

export const PanZoomContext = createContext<IPanZoomContextValue>({} as any);

export const usePanZoom = () => useContext(PanZoomContext).panZoom;
