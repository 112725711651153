import { FC } from 'react';
import {
  ApiIcon,
  ApimIcon,
  AppGatewayIcon,
  ArgoCdIcon,
  AzureFrontDoorIcon,
  AzureIcon,
  DatabaseIcon,
  DoItNowIcon,
  GithubActionIcon,
  GithubIcon,
  KibanaIcon,
  WebAppIcon,
} from './Icons';
import { NodeTypeDto } from './api/graph.api.dto';

type NodeIconSwitcherProps = { type: NodeTypeDto };

export const NodeIconSwitcher: FC<NodeIconSwitcherProps> = ({ type }: NodeIconSwitcherProps) => {
  if (type === 'website') {
    return <WebAppIcon />;
  }
  if (type === 'api') {
    return <ApiIcon />;
  }
  if (type === 'database') {
    return <DatabaseIcon />;
  }
  if (type === 'apim') {
    return <ApimIcon />;
  }
  if (type === 'appgateway') {
    return <AppGatewayIcon />;
  }
  if (type === 'frontdoor') {
    return <AzureFrontDoorIcon />;
  }
  return null;
};

type ButtonIconSwitcherProps = { icon: string; className: string | undefined };

export const ButtonIconSwitcher: FC<ButtonIconSwitcherProps> = ({ icon, className }: ButtonIconSwitcherProps) => {
  if (icon === 'kibana') {
    return <KibanaIcon />;
  }
  if (icon === 'argocd') {
    return <ArgoCdIcon />;
  }
  if (icon === 'github') {
    return <GithubIcon />;
  }
  if (icon === 'github-actions') {
    return <GithubActionIcon />;
  }
  if (icon === 'azure') {
    return <AzureIcon />;
  }
  if (icon === 'do-it-now') {
    return <DoItNowIcon />;
  }
  return <i className={`icon icon-md ${className ?? ''}`}>{icon}</i>;
};
