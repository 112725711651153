import { Tree } from '../../../components/tree';
import { fetchGraph, fetchGraphDetails } from './graph.api';
import { ComponentDetailsDto } from './graph.api.dto';
import { GraphDetailsDto } from './graphdetails.api.dto';

import mapGraphToTree, { mapToGraph } from './tree.api.mapper';

export async function fetchEntourage(_nodeId?: string): Promise<Tree | undefined> {
  const graph = await fetchGraph();
  const res = mapGraphToTree(mapToGraph(graph));
  console.log('res', res);
  return res;
}

export async function fetchComponentDetails(nodeId: string): Promise<ComponentDetailsDto> {

  const details = await fetchGraphDetails(nodeId);

  const mock: ComponentDetailsDto = {
    name: details.name,
    details: details,
    type: 'website',
    team: 'SG Markets Execution',
    links: [
      {
        type: 'kibana',
        url: '',
      },
      {
        type: 'argocd',
        url: '',
      },
      {
        type: 'github',
        url: '',
      },
      {
        type: 'github-actions',
        url: '',
      },
      {
        type: 'platormization',
        url: '',
      },
      {
        type: 'sonar',
        url: '',
      },
    ],
  };
  return defer(mock);
}

function defer<T>(obj: T, ms: number = 1000): Promise<T> {
  return new Promise((resolve) => setTimeout(() => resolve(obj), ms));
}
