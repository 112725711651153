import { FC } from 'react';

export type Menu = 'none' | 'details';

type LeftMenuProps = {
  selectedMenu: Menu;
  onMenuSelect: (menu: Menu) => void;
};

export const LeftMenu: FC<LeftMenuProps> = ({ selectedMenu, onMenuSelect }: LeftMenuProps) => {
  const handleSelect = (newValue: Menu): void => {
    onMenuSelect(newValue === selectedMenu ? 'none' : newValue);
  };

  return (
    <div className="col px-0 border-end">
      <aside>
        <ul className="nav nav-vertical-pills nav-hover">
          <MenuItem icon="info" selected={selectedMenu === 'details'} onSelect={() => handleSelect('details')} />
        </ul>
      </aside>
    </div>
  );
};

type MenuItemProps = {
  onSelect: () => void;
  icon: string;
  selected: boolean;
};

const MenuItem: FC<MenuItemProps> = ({ onSelect, icon, selected }: MenuItemProps) => (
  <li className={`d-flex nav-item justify-content-center pointer ${selected ? 'bg-lvl2' : ''}`}>
    <button className="btn btn-md btn-flat" style={{ width: '48px', height: '48px' }} onClick={() => onSelect()}>
      <span className="icon icon-md pointer text-secondary">{icon}</span>
    </button>
  </li>
);
