import { FC, ReactNode } from 'react';

type NodeButtonProps = {
  title: string;
  children: ReactNode;
  onClick: () => void;
};

export const NodeButton: FC<NodeButtonProps> = ({ title, children, onClick }: NodeButtonProps) => (
  <button
    className="node-button flex-center rounded-circle border-0 p-0 bg-light bg-opacity-25"
    type="button"
    title={title}
    onClick={(e) => {
      onClick();
      e.stopPropagation();
    }}
  >
    {children}
  </button>
);
