import { FC } from 'react';
import { useAtomValue, useSetAtom } from 'jotai';

import { TREE_ATOM, VIEWPORT_CENTER_ATOM } from '../../atoms/tree.atoms';
import { NodeHeading, NodeSubheading } from './NodeHeadings';
import NodeButtons from './NodeButtons';
import { TreeNode as TreeNodeType } from '../../tree.typing';
import { NODE_HEIGHT, NODE_WIDTH } from '../../tree.const';
import { TreeFunctions } from '../chart/TreeChart';
import LoadingSpinner from './LoadingSpinner';

import './TreeNode.scss';

export type TreeNodeProps = TreeFunctions & { node: TreeNodeType };

export const TreeNode: FC<TreeNodeProps> = ({ node, renderNode, onNodeClick, onButttonClick }: TreeNodeProps) => {
  const { id, title, subtitle, buttons } = node;

  const tree = useAtomValue(TREE_ATOM);
  const setViewPortCenter = useSetAtom(VIEWPORT_CENTER_ATOM);

  const handleOnClick = async (nodeId: string): Promise<void> => {
    if (!tree) {
      return;
    }
    onNodeClick?.(node);
    setViewPortCenter(nodeId);
  };

  const leftButtons = buttons.filter(({ side }) => side === 'left') ?? [];
  const rightButtons = buttons.filter(({ side }) => side === 'right') ?? [];

  const handleOnButtonClick = (buttonId: string): void => {
    onButttonClick?.(buttonId, node);
  };

  return (
    <div className="flex-center flex-column tree-node" style={{ width: NODE_WIDTH, height: NODE_HEIGHT }}>
      <NodeHeading heading={title} />
      <NodeSubheading heading={subtitle} />
      <div className="btn btn-link" onClick={() => handleOnClick(id)} aria-hidden="true">
        <div className="node-badge">
          {node.isLoading ? (
            <div className="flex-center w-100 h-100">
              <LoadingSpinner />
            </div>
          ) : (
            <>
              {renderNode(node)}
              <NodeButtons side="right" items={rightButtons} onButtonClick={handleOnButtonClick} />
              <NodeButtons side="left" items={leftButtons} onButtonClick={handleOnButtonClick} />
            </>
          )}
        </div>
      </div>
    </div>
  );
};
