import { ButtonItem, Tree, TreeNode } from '../../../components/tree';
import { ButtonIconSwitcher } from '../IconSwitcher';
import {
  GraphDto,
  GraphNodeDto,
  InfraInstanceDto,
  NodeStatusDto,
  SgmOrderPizzaGraphNodeDto,
  SgmOrderPizzaGraphNodeStatus,
  SgmOrderPizzaInstanceDto,
} from './graph.api.dto';

function mapToNodeDto({
  key,
  children,
  status,
  type,
  name,
  links,
  instances,
}: SgmOrderPizzaGraphNodeDto): GraphNodeDto {
  return {
    id: key,
    parent: undefined,
    children: children.map(({ key }) => key),
    status: mapToStatus(status),
    type,
    name,
    links,
    instances: instances?.map((i) => mapToInstance(i)) ?? [],
    team: '',
  };
}

function mapToInstance({ name, status }: SgmOrderPizzaInstanceDto): InfraInstanceDto {
  return {
    status: status === 'Healthy' ? 'ok' : 'ko',
    name,
    url: '',
  };
}
function mapToStatus(status: SgmOrderPizzaGraphNodeStatus): NodeStatusDto {
  switch (status) {
    case 'Unhealthy':
      return 'ko';
    case 'Degraded':
      return 'warning';
    case 'Healthy':
      return 'ok';
  }
}

export function mapToGraph(node: SgmOrderPizzaGraphNodeDto): GraphDto {
  const stack: SgmOrderPizzaGraphNodeDto[] = [node];
  let nodes: Record<string, GraphNodeDto> = {};
  while (stack.length) {
    const item = stack.pop();
    if (item) {
      nodes = { ...nodes, [item.key]: mapToNodeDto(item) };
      console.log('nodes', nodes);
      stack.push(...(item.children ?? []));
    }
  }
  return { rootId: node.key, nodes };
}

export default function mapGraphToTree({ rootId, nodes }: GraphDto): Tree {
  const tree: Tree = {
    rootId,
    nodes: Object.keys(nodes).reduce(
      (acc, nodeId) => ({
        ...acc,
        [nodeId]: mapGraphNodeToTreeNode(nodes[nodeId]),
      }),
      {}
    ),
  };
  return tree;
}

function mapGraphNodeToTreeNode({
  id,
  parent,
  children,
  name,
  team,
  status,
  type,
  links,
  instances,
}: GraphNodeDto): TreeNode {
  const leftButtons: ButtonItem[] =
    links?.map(({ type, url }) => ({
      id: `${id}-${type}`,
      side: 'left',
      icon: type,
      title: type,
      className: undefined,
      customRender: () => <ButtonIconSwitcher icon={type} className={undefined} />,
    })) ?? [];

  const rightButtons: ButtonItem[] =
    instances?.map(({ name, url, status }) => ({
      id: `${id}-${name}`,
      side: 'right',
      title: name,
      icon: status === 'ok' ? 'check_circle' : 'cancel',
      className: status === 'ok' ? 'text-success' : 'text-warning',
      customRender: undefined,
    })) ?? [];

  const treeNode: TreeNode = {
    id,
    parent,
    children,
    title: name,
    subtitle: team,
    customMetadata: { status, type },
    buttons: [...leftButtons, ...rightButtons],
    isLoading: false,
  };

  return treeNode;
}
