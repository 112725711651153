import React, { FunctionComponent } from 'react';

interface Props {
  isLoading: boolean;
  message?: string;
}

const Loading: FunctionComponent<Props> = ({
  isLoading,
  message = 'Loading...',
}: Props) =>
  isLoading ? <div className="blinker blinker-lg w-100">{message}</div> : null;

export default Loading;
