import { useEffect } from 'react';
import { Viewport, ViewportService } from '../service/viewport.service';
import { usePanZoom } from './usePanZoom';
import { LayoutTree } from '../tree.typing';
import { useAtom, useAtomValue } from 'jotai';
import { TREE_ATOM, VIEWPORT_CENTER_ATOM } from '../atoms/tree.atoms';

export const useViewport = (chartLayout: LayoutTree, selectedNodeId: string | undefined = undefined) => {
  const tree = useAtomValue(TREE_ATOM);
  const [viewportCenter, setViewportCenter] = useAtom(VIEWPORT_CENTER_ATOM);
  const panZoom = usePanZoom();

  useEffect(() => {
    if (selectedNodeId && selectedNodeId !== viewportCenter) {
      setViewportCenter(selectedNodeId);
    }
  }, [selectedNodeId, viewportCenter, setViewportCenter]);

  useEffect(() => {
    if (!tree) {
      return;
    }
    const viewport: Viewport = { type: 'center', query: viewportCenter ?? tree.rootId };
    const service = new ViewportService(panZoom, chartLayout);
    service.updateViewport(viewport);
  }, [panZoom, chartLayout, tree, viewportCenter]);
};
