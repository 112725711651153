export enum Environment {
    Local = 'Local',
    Development = 'Development',
    Homologation = 'Homologation',
    Production = 'Production',
    Test = 'Test',
}

export const getEnvironment = () => {
    let environment = Environment.Production;
    if (typeof window === 'undefined' || !window.location.hostname) {
        environment = Environment.Test;
    } else if (window.location.hostname === 'local.fr.world.socgen'
        || window.location.hostname === 'localhost') {
        environment = Environment.Local;
    } else if (window.location.hostname === 'status-dev.fr.world.sgmarkets') {
        environment = Environment.Development;
    } else if (window.location.hostname === 'status-uat..fr.world.sgmarkets') {
        environment = Environment.Homologation;
    } else if (window.location.hostname === 'status.fr.world.sgmarkets'
        || window.location.hostname === 'status-preview.fr.world.sgmarkets') {
        environment = Environment.Production;
    }
    return environment;
};


interface IConfiguration {
    baseApiUrls: {
        status: string;
    };
}

const defaultConfiguration = {
    baseApiUrls: {
        status: 'https://sgm-status-dev.sgr-3819-dev-insight-kube12-dev.wkube.eu-fr-paris.cloud.socgen',
    }
};



export const configurationPerEnvironment: Record<Environment, IConfiguration> = {
    [Environment.Local]: {
        ...defaultConfiguration,
    },
    [Environment.Development]: {
        ...defaultConfiguration,
        baseApiUrls: {
            status: 'https://sgm-status-dev.sgr-3819-dev-insight-kube12-dev.wkube.eu-fr-paris.cloud.socgen',
        },
    },
    [Environment.Homologation]: {
        ...defaultConfiguration,
        baseApiUrls: {
            status: 'https://sgm-status-dev.sgr-3819-dev-insight-kube12-dev.wkube.eu-fr-paris.cloud.socgen',
        },
    },
    [Environment.Production]: {
        ...defaultConfiguration,
        baseApiUrls: {
            status: 'https://sgm-status-dev.sgr-3819-dev-insight-kube12-dev.wkube.eu-fr-paris.cloud.socgen',
        }
    },
    [Environment.Test]: {
        ...defaultConfiguration,
    },
};

export const configuration = configurationPerEnvironment[getEnvironment()];