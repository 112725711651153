import { uniq } from 'lodash-es';
import { Tree } from '../../../components/tree';

export function mergeTrees(tree: Tree, other: Tree | undefined): Tree {
  if (!other) {
    return tree;
  }
  const rootId = Object.keys(tree.nodes).includes(other.rootId) ? tree.rootId : other.rootId;
  const newTree = { rootId, nodes: tree.nodes };

  for (const [id, otherNode] of Object.entries(other.nodes)) {
    const node = newTree.nodes[id];
    if (!node) {
      newTree.nodes[id] = otherNode;
      continue;
    }
    if (otherNode.parent) {
      node.parent = otherNode.parent;
    }
    if (otherNode.children && node.children.length !== otherNode.children.length) {
      const [heaviest, lightest] =
        node.children.length > otherNode.children.length ? [node, otherNode] : [otherNode, node];
      node.children = uniq([...heaviest.children, ...lightest.children]);
    }
  }

  return newTree;
}
