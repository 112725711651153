import { FC } from 'react';
import { TreeLink } from './TreeLink';
import { LayoutTree } from '../../tree.typing';
import { TreeNode } from '../node/TreeNode';
import { useViewport } from '../../hooks/useViewport';
import { TreeProps } from './Tree';

import './TreeChart.scss';

export type TreeFunctions = Pick<TreeProps, 'renderNode' | 'onButttonClick' | 'onNodeClick'>;

export type TreeChartProps = TreeFunctions & { layoutTree: LayoutTree; selectedNodeId: string | undefined };

export const TreeChart: FC<TreeChartProps> = ({ layoutTree, selectedNodeId, ...treeFunctions }: TreeChartProps) => {
  useViewport(layoutTree, selectedNodeId);
  const { nodes, links } = layoutTree;

  return (
    <div>
      {Object.entries(nodes).map(([key, { x, y, ...treeNode }]) => (
        <div key={key} className="node-container animated" style={{ transform: `translate(${x}px, ${y}px)` }}>
          <TreeNode node={treeNode} {...treeFunctions} />
        </div>
      ))}
      {Object.entries(links || {}).map(([id, link]) => (
        <TreeLink key={`${id}-link`} link={link} />
      ))}
    </div>
  );
};
