import React, { useLayoutEffect, useRef } from 'react';
import { OrgChart } from 'd3-org-chart';
import * as d3 from 'd3';

const OrgChartComponent: React.FC = (props: any, ref) => {
  const d3Container = useRef(null);
  let chart: any = null;

  function addNode(node: any) {
    chart.addNode(node);
  }
  //props.setClick(addNode);

  // We need to manipulate DOM
  useLayoutEffect(() => {
    if (props.data && d3Container.current) {
      if (!chart) {
        chart = new OrgChart();
        chart.svgHeight(window.innerHeight);
      }
      chart
        .nodeHeight((d: any) => 125 + 25)
        .nodeWidth((d: any) => 220 + 2)
        .childrenMargin((d: any) => 50)
        .compactMarginBetween((d: any) => 35)
        .compactMarginPair((d: any) => 30)
        .neighbourMargin((a: any, b: any) => 20)
        .linkUpdate(function (d: any, i: any, arr: any) {
          // @ts-ignore
          d3.select(this as any).attr('stroke', (d) =>
            d.data._upToTheRootHighlighted ? '#152785' : '#666'
          );

          if (d.data._upToTheRootHighlighted) {
            // @ts-ignore
            d3.select(this as any).raise();
          }
        })
        .nodeContent(function (d: any, i: any, arr: any, state: any) {
          const color = '#FFFFFF';
          const imageDiffVert = 15 + 2;
          const links = d.data.links;
          d.data.degraded = d.data.status === 'Degraded' ? true : false;
          d.data.unhealthy = d.data.status === 'Unhealthy' ? true : false;
          //chart.setUpToTheRootHighlighted(d.data.id).render().fit();
          return `
                  <div style='width:${
                    d.width
                  }px;height:${d.height}px;padding-top:${imageDiffVert - 2}px;padding-left:1px;padding-right:1px'>
                          <div style="font-family: 'Inter', sans-serif;margin-left:-1px;width:${
                            d.width - 2
                          }px;height:${d.height - imageDiffVert + 7}px;border-radius:10px;; background-color: ${d.data.degraded ? '#ff84003d' : d.data.unhealthy ? '#ff33333d' : '#67e2673d'}; border: ${d.data.degraded ? '3px solid #ff8400' : d.data.unhealthy ? '3px solid #ff3333' : '1px solid #228B22'}" >
                            <div style="display:flex;justify-content:flex-end;margin-top:5px;margin-right:8px;color:#808080; color:transparent;">#${
                              d.data.id
                            }</div>
                            <div style="background-color:${color};margin-top:${-imageDiffVert - 18}px;margin-left:${15}px;border-radius:100px;width:40px;height:40px;" ></div>
                            <div style="margin-top:${
                              -imageDiffVert - 20
                            }px;"><img src=" ${d.data.type}" style="margin-left:${20}px;border-radius:10px;width:30px;height:30px;" /></div>
                              <div style="font-size:15px;color:#08011E;margin-left:20px;margin-top:10px">  ${
                                d.data.name
                              } </div>
                              <div style="color:${
                                d.data.degraded
                                  ? '#FF8C00'
                                  : d.data.unhealthy
                                  ? '#ff3333'
                                  : '#228B22'
                              };margin-left:20px;margin-top:3px;font-size:10px;font-weight:bold">${d.data.status} </div>
                              <div style="color:#228B22;margin-left:20px;margin-top:3px;font-size:10px;font-weight:bold;line-height:30px;height:30px;">
                              <img src="${
                                d.data.certificate === 'Healthy'
                                  ? './certificate.svg'
                                  : './certificate_ko.svg'
                              }" style="width:30px;height:30px; display:${d.data.certificate === 'Unknown' ? 'none' : 'inline'}" />
                              ${d.data.nodes
                                ?.map((node) => {
                                  return `<img src="/chart/node_${node}.svg" style="margin-left:${20}px;border-radius:10px;width:30px;height:30px; display:${
                                    node === 'undefined' ? 'none' : 'inline'
                                  }" />`;
                                })
                                .join('')}
                              </div>
                              <div style="color:#228B22;margin-top:3px;font-size:10px;font-weight:bold">
                              <table style="border-top: 1px solid #E4E2E9; width: 100%; " >
                              <tr>
                                  ${links
                                    .map((item: any, index: any) => {
                                      return `<td><img src="${item.icon}" style="width:30px;height:30px;padding-top: 4px;" class="link" title="${item.name}" /></td>`;
                                    })
                                    .join('')}
                              </tr>
                              </table>
                              </div>
  
                          </div>
                      </div>
                              `;
        })
        .container(d3Container.current)
        .data(props.data)
        .onNodeClick((d: any, i: any, arr: any) => {
          console.log(d, 'Id of clicked node ');
          props.onNodeClick(d);
        })
        .expandAll()
        .fit()
        .render();
    }
  }, [props.data, d3Container.current]);

  return (
    <div>
      <div ref={d3Container} />
    </div>
  );
};

export default OrgChartComponent;
