import { useIntl } from 'react-intl';

export const Loading = () => {
  const intl = useIntl();
  return (
    <div className="blinker blinker-xl display-6">
      {intl.formatMessage({ id: 'loading' })}
    </div>
  );
};
