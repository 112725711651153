import React, { Suspense } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { IntlProvider } from './context/IntlContext';
import { GlobalContext } from './context/GlobalContext';
import StatusTree from './routes/statusTree/StatusTree';
import {
  ErrorBoundary,
  Header,
  HelpCenter,
  MiniFooter,
} from './components/common';
import { Loading } from './components/common/Loading';
import './App.scss';
import StatusChart from './routes/statusChart/StatusChart';

// Lazy loading of sub-pages. With Vite.js, it creates separate JS bundles for each page,
// which are loaded only on demand when the user go on the corresponding page.
const PageNotFound = React.lazy(
  () => import('./components/common/PageNotFound')
);
const Home = React.lazy(() => import('./routes/home/Home'));
const Services = React.lazy(() => import('./routes/services/Services'));

const Documentation = React.lazy(
  () => import('./routes/documentation/Documentation')
);

const App: React.FC = () => {
  const context = {};

  return (
    <ErrorBoundary>
      <IntlProvider>
        <GlobalContext.Provider value={context}>
          <Router>
            <Header />
            <div className="flex-grow-1">
              <Suspense fallback={<Loading />}>
                <Routes>
                  <Route path="/" element={<Home />} />
                  <Route path="/services" element={<Services />} />
                  <Route path="/status/:key" element={<StatusTree />} />
                  <Route path="/chart/:key" element={<StatusChart />} />
                  <Route path="/documentation" element={<Documentation />} />
                  <Route path="*" element={<PageNotFound />} />
                </Routes>
              </Suspense>
            </div>
            <HelpCenter />
            <MiniFooter />
          </Router>
        </GlobalContext.Provider>
      </IntlProvider>
    </ErrorBoundary>
  );
};

export default App;
